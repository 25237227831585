class Navigation {
    constructor ($element) {
        this.$menu = $element;
        this.$menuTrigger = this.$menu.querySelectorAll('[data-menu="trigger"]');
        this.$subNavi = this.$menu.querySelectorAll('.has--subnavi');
        this.$menuMobileItem = this.$menu.querySelectorAll('.offcanvas__list--primary .offcanvas__list-item');
    }

    initialize () {
        this.setEvents();
        this.handleSubnaviMobile();
    }

    setEvents () {
        for (let i = 0; i < this.$menuTrigger.length; i++) {
            this.$menuTrigger[i].addEventListener('click', () => {
                if (this.$menuTrigger[i].checked === true) {
                    if (document.querySelector('[data-header-info="mobile"]')) {
                        const headerInfoHeight = document.querySelector('[data-header-info="mobile"]').getBoundingClientRect().height;
                        const mobileMenu = document.querySelector('[data-mobile-menu]');
                        mobileMenu.style.marginTop = headerInfoHeight + 'px';
                        mobileMenu.style.height = window.innerHeight + 'px';
                        document.body.classList.add('offcanvas--is-active');
                        this.bodyScrollLock('enable');
                    } else {
                        const mobileMenu = document.querySelector('[data-mobile-menu]');
                        mobileMenu.style.height = window.innerHeight + 'px';
                        document.body.classList.add('offcanvas--is-active');
                        this.bodyScrollLock('enable');
                    }
                } else {
                    document.body.classList.remove('offcanvas--is-active');
                    this.bodyScrollLock('disable');
                }
            });
        }

        // unset layout on window resize
        window.addEventListener('resize', () => {
            const mobileMenu = document.querySelector('[data-mobile-menu]');
            mobileMenu.style.height = window.innerHeight + 'px';
            document.body.classList.remove('offcanvas--is-active');
            this.bodyScrollLock('disable');

            for (let i = 0; i < this.$menuTrigger.length; i++) {
                this.$menuTrigger[i].checked = false;
            }
        });
    }

    handleSubnaviMobile () {
        if (this.$subNavi.length > 0) {
            for (let i = 0; i < this.$subNavi.length; i++) {
                this.$subNavi[i].addEventListener('click', (e) => {
                    if (e.target.parentElement.classList.contains('subnavi--open')) {
                        this.closeSubnavi();
                        return false;
                    } else {
                        this.closeSubnavi();
                        e.target.parentElement.classList.add('subnavi--open');
                    }
                });
            }
        }
    }

    closeSubnavi () {
        for (let i = 0; i < this.$menuMobileItem.length; i++) {
            this.$menuMobileItem[i].classList.remove('subnavi--open');
        }
    }

    bodyScrollLock (status) {
        if (status === 'disable') {
            document.body.style.removeProperty('overflow');
            // data-mobile-menudocument.body.style.removeProperty('position');
            document.body.style.removeProperty('width');
        }

        if (status === 'enable') {
            document.body.style.overflow = 'hidden';
            // document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        }
    }
}

export default Navigation;
