import { uniqueID } from './../../utilities/js/helper';

class Notification {
    constructor (notificationText, notificationType, options) {
        const settings = {
            showTimeout: 150,
            hideTimeout: 3000,
            removeTimeout: 3500
        };

        this.settings = Object.assign({}, settings, options);
        this.notificationText = notificationText;
        this.notificationType = notificationType;

        this.initialize();
    }

    initialize () {
        this.$notification = this.buildNotification(this.notificationText, this.notificationType);
    }

    buildNotification (nText, nType) {
        const $container = document.createElement('div');
        const $containerInner = document.createElement('div');
        const $containerContent = document.createElement('div');
        const $body = document.querySelector('body');
        const _window = window;
        const containerId = uniqueID();

        $containerContent.classList.add('notification__content');
        $containerContent.classList.add('notification__content--' + nType);
        $containerContent.innerHTML = '<p>' + nText + '</p>';

        $containerInner.classList.add('notification__inner');
        $containerInner.appendChild($containerContent);

        $container.classList.add('notification');
        $container.setAttribute('id', containerId);
        $container.appendChild($containerInner);

        $body.append($container);

        _window.setTimeout(() => {
            $container.classList.add('is--visible');
        }, this.settings.showTimeout);

        _window.setTimeout(() => {
            $container.classList.remove('is--visible');
        }, this.settings.hideTimeout);

        _window.setTimeout(() => {
            $container.remove();
        }, this.settings.removeTimeout);
    }
}

export default Notification;
