import { tryParseJSON } from './helper';
/**
 * usage:
 * in template:
 * <button data-tracking="content_teaser|click|link">track me</button>
 *
 * in js:
 * via eventListener: bindTrackingClick($content);
 *
 * via function call:
 * sendTracking({
        category: 'category example',
        action: 'action example',
        label: `label-example-with_${variable}`
    });
 */

const bindTrackingClick = ($content) => {
    const $trackingElements = $content.querySelectorAll('[data-tracking]');

    const addEvent = ($trackingElement) => {
        $trackingElement.addEventListener('click', () => {
            const trackingData = getTrackingData($trackingElement);

            if (trackingData !== false) {
                sendTracking(trackingData);
            }
        });
    };

    for (let i = 0; i < $trackingElements.length; i++) {
        const $trackingElement = $trackingElements[i];
        addEvent($trackingElement);
    }
};

const getTrackingData = ($trackingElement) => {
    const trackingString = $trackingElement.getAttribute('data-tracking');
    const trackingData = tryParseJSON(trackingString);

    return trackingData[0];
};

const sendTracking = (data) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
};

export {
    bindTrackingClick,
    sendTracking,
    getTrackingData
};
