import './app.scss';
import domready from 'domready';
import 'lazysizes';
import Stickyfill from 'stickyfilljs';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { bindTrackingClick, sendTracking } from './utilities/js/tracking';
import { EventScroller } from './utilities/js/event-scroller';
import Notification from './components/notification/notification';
import Navigation from './layout/navigation/navigation';
import { getOffset, smoothScrollTo } from './utilities/js/helper';
import Adjust from 'Adjust';

// import libs and components

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    loader: '<b class="loading"><i>loading...</i></b>'
};

window.OPTIONS = OPTIONS;
window.eventScroller = new EventScroller();

/**
 * define function for initialising modules
 */

let mapsApiloaded = false;

const initContent = ($content) => {
    bindTrackingClick($content);
    checkSticky($content);
    loadLazyImages();

    // validate inputs
    const $inputs = $content.querySelectorAll('[data-form="input"]');
    if ($inputs.length > 0) {
        import('./components/form-input/form-input').then(({ FormInput }) => {
            for (let i = 0; i < $inputs.length; i++) {
                const $input = new FormInput($inputs[i]);
                $input.initialize();
            }
        });
    }

    const $$rteButton = $content.querySelectorAll('.rte a');
    if ($$rteButton.length > 0) {
        for (let i = 0; i < $$rteButton.length; i++) {
            const $rteButton = $$rteButton[i];

            if (!$rteButton.getAttribute('data-tracking')) {
                let trackingData = null;
                let buttonType = 'primary';

                if ($rteButton.classList.contains('is--secondary')) {
                    buttonType = 'secondary';
                }

                if ($rteButton.classList.contains('download')) {
                    const file = $rteButton.href;
                    const fileSplitted = file.split('/');
                    const fileName = fileSplitted[fileSplitted.length - 1];

                    trackingData = {
                        event: 'ce.download_file',
                        elementText: fileName
                    };
                } else if ($rteButton.classList.contains('button')) {
                    trackingData = {
                        event: 'ce.cta_click',
                        elementText: $rteButton.innerText,
                        elementType: buttonType
                    };
                }

                $rteButton.addEventListener('click', () => {
                    sendTracking(trackingData);
                });
            }
        }
    }

    // init main header
    const $headerContainer = $content.querySelector('[data-header="root"]');
    if ($headerContainer) {
        import('./layout/siteheader/siteheader').then(({ Siteheader }) => {
            const $header = new Siteheader($headerContainer);

            $header.initialize();
        });
    }

    // init parallax
    const $stageContainer = $content.querySelectorAll('[data-stage="root"]');
    import('./modules/stage/stage').then(({ Stage }) => {
        for (let i = 0; i < $stageContainer.length; i++) {
            const stage = new Stage($stageContainer[i]);

            stage.initialize();
        }
    });

    // init modal component
    const $modal = $content.querySelectorAll('[data-menu="root"]');
    if ($modal.length > 0) {
        import('./components/modal/modal').then(({ Modal }) => {
            for (let i = 0; i < $modal.length; i++) {
                const $modalContainer = new Modal($modal);

                $modalContainer.initialize();
            }
        });
    }

    // init badge component
    const $$badges = $content.querySelectorAll('[data-badge="root"]');
    import('./components/badge/badge').then(({ Badge }) => {
        for (let i = 0; i < $$badges.length; i++) {
            const badge = new Badge($$badges[i]);

            badge.initialize();
        }
    });

    // init stage videos
    const $videos = document.querySelectorAll('[data-stagevideo]');
    const $videoTriggers = document.querySelectorAll('[data-stagevideo-play]');
    if ($videos.length > 0) {
        for (let i = 0; i < $videos.length; i++) {
            const $video = $videos[i];
            const $videoTrigger = $videoTriggers[i];

            for (let i = 0; i < $videos.length; i++) {
                $videoTrigger.addEventListener('click', () => {
                    $video.play();
                    $video.setAttribute('playing', true);

                    const $videoControls = $content.querySelectorAll('[data-video-controls="root"]');
                    if ($videoControls.length > 0) {
                        console.log('dinge');
                        import('./components/video-controls/video-controls').then(({ VideoControls }) => {
                            for (let i = 0; i < $videoControls.length; i++) {
                                const $videoControlItem = new VideoControls($videoControls[i]);

                                $videoControlItem.initialize();
                            }
                        });
                    }
                });
            }

            const close = document.querySelectorAll('.modal__exit');
            close.forEach(function (close) {
                close.addEventListener('click', function (event) {
                    $video.pause();
                    $video.removeAttribute('playing', true);
                });
            });
        }
    }

    // init video controls
    /* const $videoControls = $content.querySelectorAll('[data-video-controls="root"]');
    if ($videoControls.length > 0) {
        import('./components/video-controls/video-controls').then(({ VideoControls }) => {
            for (let i = 0; i < $videoControls.length; i++) {
                const $videoControlItem = new VideoControls($videoControls[i]);

                $videoControlItem.initialize();
            }
        });
    } */

    // init app downloads in stage
    /* const $appDownloads = $content.querySelectorAll('[data-appdownload]');
    if ($appDownloads.length > 0) {
        import('./modules/app-download/app-download').then(({ AppDownload }) => {
            for (let i = 0; i < $appDownloads.length; i++) {
                const $download = new AppDownload($appDownloads[i], {
                    loader: OPTIONS.loader,
                    onSubmitted: ($result) => {
                        initContent($result);
                    }
                });

                $download.initialize();
            }
        });
    } */

    // init google map
    const $maps = $content.querySelectorAll('[data-map="root"]');
    if ($maps.length > 0) {
        const initMaps = (Maps) => {
            for (let i = 0; i < $maps.length; i++) {
                const $map = $maps[i];
                const mapId = $map.getAttribute('data-map-id');

                const districtCoords = window.cod_maps[mapId].district_coords;
                const additionalDistrictCoords = window.cod_maps[mapId].additional_district_coords;
                const mapPosition = window.cod_maps[mapId].map_position;

                const $newMap = new Maps($map, {
                    coordinates: districtCoords,
                    additionalCoordinates: additionalDistrictCoords,
                    breakpoints: OPTIONS.breakpoints,
                    position: mapPosition
                });

                $newMap.initialize();
            }
        };

        import('./components/maps/maps').then(({ Maps }) => {
            if (mapsApiloaded === false) {
                const tag = document.createElement('script');
                tag.src = '//maps.googleapis.com/maps/api/js?key=' + window.google_maps_apikey + '&callback=mapsApiReady'; // eslint-disable-line

                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                window.mapsApiReady = () => {
                    initMaps(Maps);
                    mapsApiloaded = true;
                };
            } else {
                initMaps(Maps);
            }
        });
    }

    // init image slider
    /* const $imageSlider = $content.querySelectorAll('[data-slider="root"]');
    import('./areas/slider-text-teaser/slider-text-teaser').then(({ Slider }) => {
        for (let i = 0; i < $imageSlider.length; i++) {
            const $slider = new Slider($imageSlider[i]);
            $slider.init($imageSlider[i]);
        }
    }); */

    // init downloads
    /* const $downloads = $content.querySelectorAll('[data-download="root"]');
    import('./areas/downloads/downloads').then(({ Downloads }) => {
        for (let i = 0; i < $downloads.length; i++) {
            const $download = new Downloads($downloads[i]);
            $download.init($downloads[i]);
        }
    }); */

    // init parallax
    /* const $smartphoneTeaserContainer = $content.querySelectorAll('[data-smartphone-teaser="root"]');
    import('./areas/smartphone-teaser/smartphone-teaser').then(({ SmartphoneTeaser }) => {
        for (let i = 0; i < $smartphoneTeaserContainer.length; i++) {
            const $smartphoneTeaser = new SmartphoneTeaser($smartphoneTeaserContainer[i]);
            $smartphoneTeaser.initialize();
        }
    }); */

    // anchor navigation
    /* const $$anchorNavigation = $content.querySelectorAll('[data-anchornavigation="root"]');
    if ($$anchorNavigation.length > 0) {
        import('./areas/anchor-navigation/anchor-navigation').then(({ AnchorNavigation }) => {
            for (let i = 0; i < $$anchorNavigation.length; i++) {
                const $anchorNavigation = $$anchorNavigation[i];
                const anchorNavigationAPI = new AnchorNavigation($anchorNavigation, {
                    eventScroller: eventScroller
                });
                $anchorNavigation.API = anchorNavigationAPI;
            }
        });
    } */

    // anchor init
    const $$anchor = $content.querySelectorAll('a[href^="#"], a[href*="#"]');
    for (let i = 0; i < $$anchor.length; i++) {
        const $anchor = $$anchor[i];

        if ($anchor.getAttribute('data-tabs') !== 'trigger') {
            const anchorHref = $anchor.getAttribute('href');
            const anchorParts = anchorHref.split('//');
            const anchorPath = anchorParts[0];
            let internal = false;
            let samePage = false;

            if (anchorHref === anchorPath) {
                internal = true;

                if (anchorPath.indexOf('#') === 0) {
                    samePage = true;
                }
            }

            if (internal === samePage === true) {
                $anchor.addEventListener('click', (e) => {
                    const id = anchorHref.split('#')[1];
                    const $anchorTarget = document.getElementById(id);

                    if ($anchorTarget !== null) {
                        const anchorDimensions = getOffset($anchorTarget);
                        const anchorMovement = anchorDimensions.top;
                        smoothScrollTo(0, anchorMovement);
                    } else {
                        smoothScrollTo(0, window.innerHeight, 1000);
                    }

                    e.preventDefault();
                });
            }
        }
    }

    // feed (stories)
    /* const $feeds = $content.querySelectorAll('[data-feed]');
    if ($feeds) {
        import('./areas/stories/stories').then(({ Feed }) => {
            for (let i = 0; i < $feeds.length; i++) {
                const $feed = new Feed($feeds[i]);
                $feed.initialize();
            }
        });
    } */

    // init copy button
    const $copyButton = $content.querySelector('[data-copybutton]');
    if ($copyButton) {
        import('./components/copy-button/copy-button').then(({ CopyButton }) => {
            const copyButton = new CopyButton($copyButton, {
                onCopied: () => {
                    if (typeof $copyButton.getAttribute('data-copybutton-success') === 'string') {
                        new Notification($copyButton.getAttribute('data-copybutton-success'), 'link'); // eslint-disable-line
                    }
                }
            });
            copyButton.initialize();
        });
    }

    // init pagination
    const $paginations = $content.querySelectorAll('[data-pagination="root"]');
    import('./components/pagination/pagination').then(({ Pagination }) => {
        for (let i = 0; i < $paginations.length; i++) {
            const pagination = new Pagination($paginations[i], {
                loader: OPTIONS.loader,
                onSubmitted: (result) => {
                    initContent(result);
                    pagination.initialize();
                }
            });

            pagination.initialize();
        }
    });

    // init newsletter-forms
    const $newsletterForms = $content.querySelectorAll('[data-newsletterform="root"]');
    if ($newsletterForms.length > 0) {
        import('./areas/newsletter-signup/newsletter-signup').then(({ NewsletterForm }) => {
            for (let i = 0; i < $newsletterForms.length; i++) {
                const newsletterForm = new NewsletterForm($newsletterForms[i], {
                    loader: OPTIONS.loader,
                    onSubmitted: ($result) => {
                        initContent($result);
                    }
                });

                newsletterForm.initialize();
            }
        });
    }

    // init forms
    const $friendlyCaptcha = $content.querySelectorAll('[data-friendlycaptcha="root"]');
    if ($friendlyCaptcha.length > 0) {
        import('./components/friendly-captcha/friendly-captcha').then(({ FriendlyCaptcha }) => {
            for (let i = 0; i < $friendlyCaptcha.length; i++) {
                const friendlyCaptcha = new FriendlyCaptcha($friendlyCaptcha[i]);
                friendlyCaptcha.initialize();
            }
        });
    }

    // init forms
    const $contactForms = $content.querySelectorAll('[data-contactform="root"]');
    if ($contactForms.length > 0) {
        import('./components/form/form').then(({ ContactForm }) => {
            for (let i = 0; i < $contactForms.length; i++) {
                const contactForm = new ContactForm($contactForms[i], {
                    loader: OPTIONS.loader,
                    onSubmitted: (result) => {
                        initContent(result);
                    }
                });

                contactForm.initialize();
            }
        });
    }

    // validate forms
    const $formValidation = $content.querySelectorAll('[data-validate="root"]');
    if ($formValidation.length > 0) {
        import('./components/form/form-validation.js').then(({ Validation }) => {
            for (let i = 0; i < $formValidation.length; i++) {
                const validation = new Validation($formValidation[i]);
                validation.initialize();
            }
        });
    }

    // init cocreation
    /* const $deselectGroup = $content.querySelectorAll('[data-deselect-group="root"]');
    if ($deselectGroup.length > 0) {
        import('./areas/co-creation/co-creation').then(({ CoCreation }) => {
            for (let i = 0; i < $deselectGroup.length; i++) {
                const creationForm = new CoCreation($deselectGroup[i]);
                creationForm.initialize();
            }
        });
    } */

    // init jobfilter
    /* const $jobFilters = $content.querySelectorAll('[data-jobs="root"]');
    if ($jobFilters.length > 0) {
        import('./areas/jobs/jobs').then(({ Jobs }) => {
            for (let i = 0; i < $jobFilters.length; i++) {
                const jobs = new Jobs($jobFilters[i], {
                    onSubmitted: (result) => {
                        initContent(result);
                        jobs.initialize();
                    }
                });

                jobs.initialize();
            }
        });
    } */

    // init job pagination
    /* const $jobPagination = $content.querySelectorAll('[data-jobs-pagination="root"]');
    import('./areas/jobs/jobs-pagination').then(({ JobsPagination }) => {
        for (let i = 0; i < $jobPagination.length; i++) {
            const jobPagination = new JobsPagination($jobPagination[i], {
                loader: OPTIONS.loader,
                onSubmitted: (result) => {
                    initContent(result);
                    jobPagination.initialize();
                }
            });

            jobPagination.initialize();
        }
    }); */

    // init full bleed video
    /* const $fullBleedVideo = $content.querySelectorAll('[data-full-bleed-video="media"]');
    if ($fullBleedVideo.length > 0) {
        import('./areas/full-bleed-video/full-bleed-video').then(({ FullBleedVideo }) => {
            for (let i = 0; i < $fullBleedVideo.length; i++) {
                const fullBleedVideo = $fullBleedVideo[i];
                fullBleedVideo.API = new FullBleedVideo(fullBleedVideo, {
                    loader: OPTIONS.loader
                });
            }
        });
    } */

    // init rte tables
    const $rteTables = $content.querySelectorAll('.rte table');
    if ($rteTables.length > 0) {
        import('./components/rte/rte-table').then(({ RteTable }) => {
            for (let i = 0; i < $rteTables.length; i++) {
                const rteTable = new RteTable($rteTables[i]);
                rteTable.initialize();
            }
        });
    }

    // init Adjust smart banner
    Adjust.initSdk({
        appToken: 'na72bqc48ow0',
        environment: 'production'
    });
    Adjust.initSmartBanner({
        webToken: 'udzdfi651jwg'
    });

    if (typeof window.Modernizr !== 'undefined') {
        if (!window.Modernizr.csshover) {
            const $hovers = $content.querySelectorAll('[data-hover], .textlink, .button');
            const hoverClass = 'hover';

            if ($hovers.length > 0) {
                for (let i = 0; i < $hovers.length; i++) {
                    const $hover = $hovers[i];

                    $hover.addEventListener('touchstart', () => {
                        if (!$hover.classList.contains(hoverClass)) {
                            $hover.classList.add(hoverClass);
                        }
                    }, { passive: true });
                }

                document.addEventListener('touchstart', (e) => {
                    const $hoveredElements = $content.getElementsByClassName(hoverClass);

                    for (let i = 0; i < $hoveredElements.length; i++) {
                        const $currentHover = $hoveredElements[i];
                        const isClickInside = $currentHover.contains(e.target);

                        if (!isClickInside) {
                            if ($currentHover.classList.contains(hoverClass)) {
                                $currentHover.classList.remove(hoverClass);
                            }
                        }
                    }
                }, { passive: true });
            }
        }
    }
};

const checkSticky = ($content) => {
    const stickyElements = $content.querySelectorAll('.sticky');
    if (document.documentElement.classList.contains('ie10') || document.documentElement.classList.contains('ie11')) {
        Stickyfill.add(stickyElements);
    }
};

/* const setOptOutAnchorLinks = () => {
    const $gaOptOutLink = document.getElementById('ga-opt-out');
    if ($gaOptOutLink) {
        $gaOptOutLink.setAttribute('href', '');
        $gaOptOutLink.addEventListener('click', (e) => {
            e.preventDefault();
            if (typeof gaOptout !== 'undefined') {
                gaOptout(); // eslint-disable-line
            }
        });
    }
}; */

/**
 * starting point
 */

domready(() => {
    const initApplication = () => {
        initContent(document);

        window.eventScroller.init();
        window.initContent = initContent;

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        const $navigation = document.querySelector('[data-menu="root"]');
        if ($navigation) {
            const $menu = new Navigation($navigation);
            $menu.initialize();
        }

        window.dispatchEvent(contentUpdateEvent);

        // Remove no Transitions for all Elements at Start
        setTimeout(() => {
            document.body.classList.remove('has--no-animation');
        }, 1000);
    };

    const handleOneTrust = ($links) => {
        $links.forEach($link => {
            $link.addEventListener('click', (e) => {
                if (typeof OneTrust !== 'undefined') { // eslint-disable-line
                    OneTrust.ToggleInfoDisplay(); // eslint-disable-line
                }
                e.preventDefault();
            });
        });
    };

    handleOneTrust(document.querySelectorAll('[rel="onetrust-link"]'));

    if (!window.Modernizr.ie11) {
        initApplication();
    }
});
